import {
  createRouter,
  createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
    path: '/',
    name: 'home',
    meta: {
      title: '',
      desc: ''
    },
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '探究学習データベースとは | ',
      desc: ''
    },
    component: () => import('../views/about/AboutView.vue')
  },
  {
    path: '/news_list',
    name: 'news_list',
    meta: {
      title: '新着情報 | ',
      desc: ''
    },
    component: () => import('../views/news/ListView.vue')
  },
  {
    path: '/news_list/:page([0-9]*)',
    name: 'news_list',
    meta: {
      title: '新着情報 | ',
      desc: ''
    },
    component: () => import('../views/news/ListView.vue')
  },
  {
    path: '/news_detail/:pk([0-9]*)',
    name: 'news_detail',
    meta: {
      title: '新着情報 | ',
      desc: ''
    },
    component: () => import('../views/news/DetailView.vue')
  },
  {
    path: '/search_list',
    name: 'search_list',
    meta: {
      title: '探究学習を探す | ',
      desc: ''
    },
    component: () => import('../views/search/ListView.vue')
  },
  {
    path: '/search_list/:page([0-9]*)',
    name: 'search_list',
    meta: {
      title: '探究学習を探す | ',
      desc: ''
    },
    component: () => import('../views/search/ListView.vue')
  },
  {
    path: '/search_detail/:pk([0-9]*)',
    name: 'search_detail',
    meta: {
      title: '探究学習を探す | ',
      desc: ''
    },
    component: () => import('../views/search/DetailView.vue')
  },
  {
    path: '/contact_form',
    name: 'contact_form',
    meta: {
      title: 'お問い合わせ | ',
      desc: ''
    },
    component: () => import('../views/contact/FormView.vue')
  },
  {
    path: '/contact_conf',
    name: 'contact_conf',
    meta: {
      title: 'お問い合わせ | ',
      desc: ''
    },
    component: () => import('../views/contact/ConfView.vue')
  },
  {
    path: '/contact_thanks',
    name: 'contact_thanks',
    meta: {
      title: 'お問い合わせ | ',
      desc: ''
    },
    component: () => import('../views/contact/ThanksView.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    meta: {
      title: 'プライバシーポリシー | ',
      desc: ''
    },
    component: () => import('../views/policy/PolicyView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'ログイン | ',
      desc: ''
    },
    component: () => import('../views/login/FormView.vue')
  },
  {
    path: '/signup_form',
    name: 'signup_form',
    meta: {
      title: '新規登録 | ',
      desc: ''
    },
    component: () => import('../views/signup/FormView.vue')
  },
  {
    path: '/signup_conf',
    name: 'signup_conf',
    meta: {
      title: '新規登録 | ',
      desc: ''
    },
    component: () => import('../views/signup/ConfView.vue')
  },
  {
    path: '/signup_thanks',
    name: 'signup_thanks',
    meta: {
      title: '新規登録 | ',
      desc: ''
    },
    component: () => import('../views/signup/ThanksView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    // hash or topへスクロール
    if (to.hash) {
      if (!from.name) {
        //初回のみ
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              el: to.hash
            })
          }, 100)
        })
      } else {
        return {
          el: to.hash
        };
      }
    } else {
      return {
        top: 0
      };
    }
  },
})

//title meta
router.afterEach((to) => {
  document.title = to.meta.title + 'おかやまシェア・ウェブ',
    document.querySelector("meta[name='description']").setAttribute('content', to.meta.desc)

})

export default router
