<template>
  <h1>
    <a href="/">
      <img src="/assets/img/common/logo01.webp" alt="岡山県内高校の探究学習結果データベース おかやまシェアウェブ">
    </a>
  </h1>
  <div class="header_btn" v-on:click="menu = !menu" v-bind:class="{ _active: menu }">
    <span>
      <img src="/assets/img/common/icon_menu.svg">
      <img src="/assets/img/common/icon_closed.svg">
    </span>
  </div>
  <div class="menu" v-bind:class="{ _active: menu }">
    <div class="menu_cont">
      <ul>
        <li><a href="/" class="link_anchor">HOME</a></li>
        <li class="sp"><a href="/news_list/">お知らせ</a></li>
        <li><a href="/about/" class="link_anchor">探究学習データベースとは</a></li>
        <li><a href="/search_list/" class="link_anchor">探究学習を探す</a></li>
        <li><a href="/contact_form/" class="link_anchor">お問い合わせ</a></li>
        <li class="sp"><a href="/policy/">プライバシーポリシー</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HeaderInclude",

  data() {
    return {
      menu: false,
    };
  },
  methods: {
    menuBox: function () {
      this.menu = true;
    },
  },


};
</script>
