<template>
  <a class="footer__pagetop" v-bind:class="{ _active: scrollTopOn }" href="#"><span>TOPへ</span></a>

  <ul class="footer__banner">
    <li><a href="http://okayama-share.jp/portal/" target="_blank"><img src="/assets/img/common/banner01.webp"
          alt="おかやまボランティア・NPOポータルサイト SOCIAL GOOD OKAYAMA!"></a></li>
    <li><a href="http://youi-c.okayama-share.jp/" target="_blank"><img src="/assets/img/common/banner02.webp"
          alt="ゆうあいセンター 岡山県ボランティア・NPO活動支援センター"></a></li>
  </ul>
  <div class="footer__in">
    <div class="footer__title">
      <h1><a href="/"><img src="/assets/img/common/logo02.webp" alt="岡山県内高校の探究学習結果データベース おかやまシェアウェブ"></a></h1>
      <div class="copyright">©︎ 2024 OKAYAMA SHARE WEB</div>
    </div>
    <ul>
      <li><a href="/">ホーム</a></li>
      <li><a href="/news_list/">お知らせ</a></li>
      <li><a href="/about/">探究学習データベースとは</a></li>
      <li><a href="/search_list/">探究学習を探す</a></li>
      <li><a href="/contact_form/">お問い合わせ</a></li>
      <li><a href="/policy/">プライバシーポリシー</a></li>
    </ul>
  </div>
  <div class="footer__pr">おかやまシェアウェブは、NPO法人瀬戸内オリーブ基金の「2023年度ゆたかなふるさとをつくる瀬戸内オリーブ基金助成」を活用して制作しています。</div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: 'FooterInclude',
  setup() {

    const scrollTopOn = ref();
    const scrollTopIn = ref();
    const scrollFooter = ref();

    //pagetopの表示

    function showScrollTop() {
      if (window.scrollY >= (window.innerHeight / 2)) {
        scrollTopOn.value = true
      } else {
        scrollTopOn.value = false
      }
    }

    onMounted(() => {
      window.addEventListener("scroll", showScrollTop, { passive: true })


    })


    return {
      showScrollTop,
      scrollTopOn,
      scrollTopIn,
      scrollFooter,
    }

  }
};
</script>