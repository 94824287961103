<script>

import axios from 'axios'

import { useLoading } from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

//ajax通信のグローバル設定
axios.create({
  auth: {
    username: 'admin',
    password: 'test'
  }
})
axios.defaults.withCredentials = true

export default function ComFunc() {

  async function getJson($params) {

    //パラメーターセット
    let params = new URLSearchParams();
    for (let key in $params) {
      params.append(key, $params[key]);
    }

    return await axios
      .post(process.env.VUE_APP_BASE_URL, params, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((res) => {
        return res.data
      })
      .catch((e) => {
        alert(e);
      })
  }

  async function zipCode(zip) {
    const params = {
      c: "zipcode",
      zip: zip,
    };
    const json = (zip.match(/^[0-9]{3}-?[0-9]{4}$/)) ? await getJson(params) : '';
    return (json) ? [json.pref, json.address] : ['', '']

  }
  function sanma($num) {
    return Number($num).toLocaleString()
  }

  function toBr(str) {
    if (str) return str.replace(/\r?\n/g, "<br>");
  }

  function chgDateFormat(date,separater) {
    date = String(date)
    return date.replace(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/, function() {
      return arguments[1] + separater + arguments[2] + separater + arguments[3]
    })
  }

  function splitedList(str,sep) {
    str = String(str)
    return (str !== null) ? str.split(sep) : ''
  }

  function unNormInput(str) {
    var ret = String(str)
    
    ret = ret.replace(/&amp;/g,'&')
    ret = ret.replace(/&quot;/g,'"')
    ret = ret.replace(/&#39;/g,'\'')
    ret = ret.replace(/&lt;/g,'<')
    ret = ret.replace(/&gt;/g,'>')
    
    return ret;
  }

  // テキストにマスクをかける
  function txtMask(str, mask) {
    let maskStr = ''

    if (str) {
      for (let i = 0; i < str.length; i++) {
        maskStr += mask
      }
    }
    return maskStr
  }

  //loading
  function loadingShow() {

    const loader = useLoading();

    const onCancel = () => {
      console.log('User cancelled the loader.')
    };

    return loader.show({
      // Optional parameters
      container: null,
      canCancel: true,
      loader: "dots",
      color: "#132d5d",
      backgroundColor: "#fff",
      opacity: 0.8,
      zIndex: 999,
      onCancel: onCancel
    })
  }

  //----------------
  //バリデーション
  //----------------
  function validateName(val) {
    if (!val) {
      return '正しく入力してください';
    }
    const regex = /.+?(\x20|\u3000).*?[^\x20|\u3000]/;
    if (!regex.test(val)) {
      return '姓と名の間にスペース(半角/全角)を入力してください';
    }
    return true;
  }

  function validateEmail(val) {
    if (!val) {
      return '正しく入力してください';
    }
    const regex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}.[A-Za-z0-9]{1,}$/;
    if (!regex.test(val)) {
      return '正しく入力してください';
    }
    return true;
  }
  function validatePassword(val) {
    if (!val) {
      return '正しく入力してください';
    }
    if (val.length < 8 || val.length > 20) {
      return '8文字以上20文字以内で入力してください';
    }
    return true;
  }
  function validatePasswordChg(val) {
    if ( val && ( val.length < 8 || val.length > 20 ) ) {
      return '8文字以上20文字以内で入力してください';
    }
    return true;
  }
  function validateRequired(val) {
    if (!val) {
      return '正しく入力してください';
    }
    return true;
  }
  function validateZip(val) {
    if (!val) {
      return '正しく入力してください';
    }
    const regex = /^[0-9]{3}-?[0-9]{4}$/;
    if (!regex.test(val)) {
      return '半角数字(ハイフンはどちらでも可)で入力してください';
    }
    return true;
  }
  function validateTel(val) {
    if (!val) {
      return '正しく入力してください';
    }
    const regex = /^\d{2,4}(-|)?\d{2,4}(-|)?\d{4}$/;
    if (!regex.test(val)) {
      return '半角数字(ハイフンはどちらでも可)で入力してください';
    }
    return true;
  }
  function validateAgree(val) {
    if (!val) {
      return '同意してください';
    }
    return true;
  }
  return {
    getJson,
    zipCode,
    sanma,
    toBr,
    chgDateFormat,
    splitedList,
    unNormInput,
    txtMask,
    loadingShow,
    validateName,
    validateRequired,
    validateEmail,
    validatePassword,
    validatePasswordChg,
    validateZip,
    validateTel,
    validateAgree
  }

}
</script>