<template>
  <div class="home">
    <main>
      <div class="home__mainimage">
        <div class="home__mainimage_in">
          <div class="home__mainimage_title">
            <div class="home__mainimage_title_copy"><img src="assets/img/home/main_copy.svg" alt="さがす、みつける、わかる。"></div>
            <div class="home__mainimage_title_chara">
              <div class="home__mainimage_title_chara_eyes">
                <img src="assets/img/home/main_chara01_eyes.webp">
                <img src="assets/img/home/main_chara01_eyes.webp">
              </div>
              <img src="assets/img/home/main_chara01.webp">
            </div>
            <p>おかやまの高校生が<br class="sp">探究した<br class="pc">地域の課題や気づきを<br class="sp">みんなでシェア！</p>
            <h2><span>岡山県内高校の<br class="sp">探究学習結果データベース</span></h2>
          </div>
          <img class="obj01" src="assets/img/home/main_obj01.svg">
          <img class="obj02" src="assets/img/home/main_obj02.svg">
          <img class="obj03" src="assets/img/home/main_obj03.svg">
          <img class="obj04" src="assets/img/home/main_obj04.svg">
          <img class="obj05" src="assets/img/home/main_obj05.svg">
          <img class="obj06" src="assets/img/home/main_obj06.svg">
          <img class="obj07" src="assets/img/home/main_obj07.svg">
        </div>
      </div>
      <section>
        <div class="home__learn">

          <div class="home__learn_in">
            <div class="inner">
              <div class="title__wrap">
                <div class="title01">
                  <p><span>T</span>O LEARN!</p>
                  <h2>探究結果を見る</h2>
                </div>
                <a href="/search_list/">一覧を見る</a>
              </div>

              <ul class="home__learn_list" v-if="reacObj.learnList">
                <li v-for="(ref, $index) in reacObj.learnList" :key="$index">
                  <a :href="'/search_detail/' + ref.pk">
                    <figure><img :src="ref.F_4"></figure>
                    <div class="home__learn_list_update">{{ chgDateFormat(ref.entdt, '.') }}<span>{{ ref.F_2 }}</span>
                    </div>
                    <p>{{ ref.TITLE }}</p>
                    <aside v-if="ref.F_3">
                      <span v-for="(name, $index) in splitedList(ref.F_3, '\\n')" :key="$index">{{ name }}</span>
                    </aside>
                  </a>
                </li>
              </ul>


            </div>
            <div class="title02">
              <img src="assets/img/home/icon_learn.webp">
              <p>高校生が注目！</p>
              <h3>みんなの探究学習</h3>
            </div>
            <ul class="home__learn_list _style02" v-if="reacObj.pickupLearnList">
              <li v-for="(ref, $index) in reacObj.pickupLearnList" :key="$index">
                <a :href="'/search_detail/' + ref.pk">
                  <figure><img :src="ref.F_4"></figure>
                  <div class="home__learn_list_update">{{ chgDateFormat(ref.entdt, '.') }}<span>{{ ref.F_2 }}</span>
                  </div>
                  <p>{{ ref.TITLE }}</p>
                  <aside v-if="ref.F_3">
                    <span v-for="(name, $index) in splitedList(ref.F_3, '\\n')" :key="$index">{{ name }}</span>
                  </aside>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <div class="home__news">
          <div class="inner">
            <div class="title__wrap">
              <div class="title01">
                <p><span>N</span>EWS!</p>
                <h2>新着情報</h2>
              </div>
              <a href="/news_list/">一覧を見る</a>
            </div>

            <ul v-if="reacObj.newsList">
              <!-- loop -->
              <li v-for="(ref, $index) in reacObj.newsList" :key="$index">
                <a :href="'/news_detail/' + ref.pk + '/'">
                  <span>{{ chgDateFormat(ref.entdt, '.') }}</span>
                  <p>{{ ref.TITLE }}</p>
                </a>
              </li>
              <!-- /loop -->
            </ul>
          </div>
        </div>
      </section>
      <section>
        <div class="home__search">
          <div class="inner">
            <div class="title01">
              <p><span>S</span>EARCH!</p>
              <h2>探究学習を検索する</h2>
            </div>
            <div class="home__search_in">
              <h3>SDGs17の目標から検索</h3>
              <ul>
                <li>
                  <figure><img src="assets/img/common/sdgs01.webp" alt="1貧困をなくそう"></figure>
                  <p>貧困をなくそう</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs02.webp" alt="2飢餓をゼロに"></figure>
                  <p>飢餓をゼロに</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs03.webp" alt="3すべての人に健康と福祉を"></figure>
                  <p>すべての人に健康と福祉を</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs04.webp" alt="4質の高い教育をみんなに"></figure>
                  <p>質の高い教育をみんなに</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs05.webp" alt="5ジェンダー平等を実現しよう"></figure>
                  <p>ジェンダー平等を実現しよう</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs06.webp" alt="6安全な水とトイレを世界中に"></figure>
                  <p>安全な水とトイレを世界中に</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs07.webp" alt="7エネルギーをみんなにそしてクリーンに"></figure>
                  <p>エネルギーをみんなにそしてクリーンに</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs08.webp" alt="8働きがいも経済成長も"></figure>
                  <p>働きがいも経済成長も</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs09.webp" alt="9産業と技術革新の基盤をつくろう"></figure>
                  <p>産業と技術革新の基盤をつくろう</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs10.webp" alt="10産業と技術革新の基盤をつくろう"></figure>
                  <p>産業と技術革新の基盤をつくろう</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs11.webp" alt="11住み続けられるまちづくりを"></figure>
                  <p>住み続けられるまちづくりを</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs12.webp" alt="12つくる責任つかう責任"></figure>
                  <p>つくる責任つかう責任</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs13.webp" alt="13気候変動に具体的な対策を"></figure>
                  <p>気候変動に具体的な対策を</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs14.webp" alt="14海の豊かさを守ろう"></figure>
                  <p>海の豊かさを守ろう</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs15.webp" alt="15陸の豊かさも守ろう"></figure>
                  <p>陸の豊かさも守ろう</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs16.webp" alt="16平和と公平をすべての人に"></figure>
                  <p>平和と公平をすべての人に</p>
                </li>
                <li>
                  <figure><img src="assets/img/common/sdgs17.webp" alt="17パートナーシップで目標を達成しよう"></figure>
                  <p>パートナーシップで目標を達成しよう</p>
                </li>
              </ul>
            </div>
            <div class="home__search_btn">
              <p><span>エリア・フリーワードからも<br class="sp">検索できる！</span></p>
              <a href="/search_list/">探究結果の一覧は<br class="sp">こちらから</a>
            </div>
            <a href="/sys_manage/index.cgi" class="btn01 _green _return">探究学習結果を投稿する</a>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import ComFunc from "/src/components/ComFunc.vue";
import { onMounted, reactive } from "vue";

export default {
  name: "HomeView",
  setup() {
    const { getJson, chgDateFormat, splitedList } = ComFunc();
    const reacObj = reactive({
      learnList: [],
      pickupLearnList: [],
      newsList: [],
    });

    onMounted(async () => {
      //探究JSON
      const learnParams = {
        m_cms_pk: 1,
      };
      const learnJson = await getJson(learnParams);
      reacObj.learnList = learnJson.slice(0, 3);

      //注目の探究
      reacObj.pickupLearnList = learnJson.filter(function (data) {
        var ret = true

        if (!data.F_1) ret = false

        return ret
      }).slice(0, 6)

      //新着情報JSON
      const newsParams = {
        m_cms_pk: 2,
      };
      const newsJson = await getJson(newsParams);
      reacObj.newsList = newsJson.slice(0, 5);
    });

    return {
      reacObj,
      chgDateFormat,
      splitedList
    };
  },
};
</script>
