<template>

  <header class="header">
    <Header ref="header" />
  </header>

  <router-view />

  <footer class="footer">
    <Footer ref="footer" />
  </footer>
</template>

<script>
// @ is an alias to /src
import Footer from "@/views/include/Footer.vue";
import Header from "@/views/include/Header.vue";
import { ref, onMounted } from "vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  setup() {
    // ヘッダー・フッターJSONをパスする準備
    const header = ref(null);
    const footer = ref(null);

    onMounted(() => {
      setHeight();
    });

    // iOS 100vh対応
    function setHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    return {
      header,
      footer,
      setHeight,
    };
  },
};
</script>
